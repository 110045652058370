import React from 'react';
import Helmet from 'react-helmet';
import { Image, CloudinaryContext } from 'cloudinary-react';
import cloudinary from 'cloudinary-core';

import ContactModel from '../components/ContactModel';
import fonts from './../assets/fonts/circular-std-book/style.scss';

// SVG
import instagramIcon from './../assets/icon/instagram.svg';
import facebookIcon from './../assets/icon/facebook.svg';
import youtubeIcon from './../assets/icon/youtube.svg';
import gooseLogo from './../assets/icon/goose-logo.svg';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout/layout';

// Client Logo SVG
import bpureClient from './../assets/client/be_pure.webp';
import bstoreClinet from './../assets/client/board-store.webp';
import bmatesClient from './../assets/client/breast-mates.webp';
import brewsClient from './../assets/client/brew_shop.webp';
import cloggerClient from './../assets/client/clogger.webp';
import cGroupClient from './../assets/client/comfort-group.webp';
import cSlClient from './../assets/client/csl.webp';
import eFieldClient from './../assets/client/englefield.webp';
import enovisClient from './../assets/client/enovis.webp';
import eApplienceClient from './../assets/client/extreme-applience.webp';
import jsBrooksClient from './../assets/client/js_brooks.webp';
import kiwiVeloClient from './../assets/client/kiwivelo.webp';
import kohlarClient from './../assets/client/kohlar.webp';
import lDirectClient from './../assets/client/lighting-direct.webp';
import makanaClient from './../assets/client/makana.webp';
import mBenClient from './../assets/client/magnes-benrow.webp';
import mazdaClient from './../assets/client/mazda.webp';
import nSocksClient from './../assets/client/not_socks.webp';
import nPostClient from './../assets/client/nz-post.webp';
import queenBClient from './../assets/client/QueenB.webp';
import tSpaceClient from './../assets/client/tile-space.webp';
import tShedClient from './../assets/client/tin-shed.webp';
import vDyksClient from './../assets/client/van-dyks.webp';
import zwillingClient from './../assets/client/zwilling.webp';
import gooseAwards from './../assets/images/awardimage.webp';
import gooseAwardsMob from './../assets/images/awards-goose-mob.png';

const cloudinaryCore = new cloudinary.Cloudinary({ cloud_name: 'wearegoosebclandingpage', secure: true });

const IconInsta = () => (
  <svg viewBox={instagramIcon.viewBox}>
    <use xlinkHref={instagramIcon.url} />
  </svg>
);
const IconFacebook = () => (
  <svg viewBox={facebookIcon.viewBox}>
    <use xlinkHref={facebookIcon.url} />
  </svg>
);
const IconYoutube = () => (
  <svg viewBox={youtubeIcon.viewBox}>
    <use xlinkHref={youtubeIcon.url} />
  </svg>
);

const LogoGoose = () => (
  <svg viewBox={gooseLogo.viewBox}>
    <use xlinkHref={gooseLogo.url} />
  </svg>
);



class IndexRoute extends React.Component {
  state = { contactModelActive: false };

  handleContactModelClick = () => {
    this.setState({ contactModelActive: !this.state.contactModelActive });
  };

  createBackgroundImage = (publicId) => {
    const quality = 80;
    const secure = true;
    const t = new cloudinary.Transformation();
    t.crop('scale').dpr('auto').quality(quality);
    return cloudinaryCore.url(publicId, t);
  }


  render() {
    const items = [];

    const {
      title,
      subtitle,
      contactNumber,
      keywords,
      socialLinks
    } = this.props.data.site.siteMetadata;

    return (
      <Layout>
      <div>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={subtitle} />
          <meta name="keywords" content={keywords} />
          <meta name="google-site-verification" content="EvXTnBK1M-6Evcq9gGD33rX8b-ZZ-HSGdrnC8oc7lSQ" />
        </Helmet>
        {/* <tagManagerHead />
        <tagManagerBody /> */}
        <CloudinaryContext cloudName="wearegoosebclandingpage" fetchFormat="auto" secure="true" width="auto" quality="auto" dpr="auto">
          <header className="c-header l-header">
            <div className="c-header__logo o-logo">
              <LogoGoose />
            </div>
            <div className="c-header__nav">
              <Link onClick={this.handleContactModelClick} className="u-h6" to="/">Get in touch </Link>

              {/* <ul>
                <li><Link className="u-h6" to="/">Get in touch </Link></li>
                <li><Link className="u-h6" to="/">0211545302</Link></li>
              </ul> */}

            </div>
          </header>
          <div className="c-hero l-hero overlap-wrapper">
            <div className="overlap-bottom">
              <div className="overlap-sticky">
                <Image className="image-cover" alt="BigCommerce Marketing Expert" publicId="sanya-bigcommerce-expert" />
                <div className="c-homeIntro__image__hashtag u-large">
                  <a href="https://www.instagram.com/wearegoose.co.nz/">#thegaggle</a>
                </div>
                <div className="o-socialIcons">
                  <a className="o-socialIcons__icon o-socialIcons__icon--inverse" href="https://www.instagram.com/wearegoose.co.nz/"><IconInsta /> <span>Follow Us</span></a>
                </div>
                {/* <img className="image-cover" alt="A group of young kids laugh, framed by a rainbow in the sky above them." src="https://assets.website-files.com/6000971fd4b15ea4ee9f6405/602725dacb8bdcf9ea3936db_hero-slide__saving-orphans.jpg" /> */}
              </div>
            </div>
            <div className="overlay-top">
              <div className="header-overlap-sticky">
                <section className="c-heroContent l-heroContent">
                  <div className="c-heroContent__col--1 l-heroContent__col--1">
                    <div className="c-heroContent__row--1 l-heroContent__row--1">
                      <div className="testwrap">
                        <strong>BigCommerce Web Designers</strong>
                      </div>
                      <div className="c-heroContent__mobHeader">
                        <span className="c-hero-title u-hero">Trusted<br /> Ecommerce<br />Experts</span>
                        <div className="c-platform-logos">
                          <ul className="c-platform-logos__list">
                            <li className="c-platform-logos__item"><svg viewBox="-1240 629 64 64"><path d="M-1206.3 665.4h8.1c2.3 0 3.8-1.3 3.8-3.3 0-1.9-1.5-3.3-3.8-3.3h-8.1c-.3 0-.5.2-.5.5v5.7c.1.2.3.4.5.4zm0 13.1h8.3c2.6 0 4.1-1.3 4.1-3.6 0-2-1.5-3.6-4.1-3.6h-8.3c-.3 0-.5.2-.5.5v6.2c.1.3.3.5.5.5zm29.6-49.3l-23.9 23.8h3.9c6.1 0 9.7 3.8 9.7 8 0 3.3-2.2 5.7-4.6 6.8-.4.2-.4.7 0 .8 2.8 1.1 4.7 3.9 4.7 7.3 0 4.7-3.2 8.5-9.3 8.5h-16.9c-.3 0-.5-.2-.5-.5v-18l-26.2 26.1c-.3.3-.1 1 .4 1h63c.2 0 .4-.2.4-.4v-63.1c.1-.4-.4-.6-.7-.3z" /></svg></li>
                            <li className="c-platform-logos__item"><svg viewBox="-1240 629 64 64"><path d="M-1194.9 638.4l2.2 2.2s4.9.4 5.1.4c.3 0 .5.2.6.5 0 .3 6.9 47.2 6.9 47.2l-16.9 3.7 2.1-54zm-2.6-1.8c-.1 0-.2 0-.3.1l-1.9.6c-1.1-3.3-3.1-6.3-6.7-6.3h-.3c-1-1.3-2.2-1.9-3.3-1.9-8.2 0-12.1 10.3-13.3 15.5-3.2 1-5.4 1.7-5.7 1.8-1.8.6-1.8.6-2.1 2.3-.2 1.3-4.8 37.4-4.8 37.4l36.3 6.8 2.1-56.3zm-9.5 2.6v.4l-6.3 2c1.2-4.7 3.5-7 5.5-7.9.5 1.2.8 3 .8 5.5zm-3.2-7.9c.4 0 .7.1 1.1.4-2.6 1.2-5.4 4.4-6.6 10.6l-5 1.6c1.3-4.8 4.6-12.6 10.5-12.6zm1.4 27.9s-2.1-1.1-4.7-1.1c-3.8 0-4 2.4-4 3 0 3.3 8.6 4.6 8.6 12.3 0 6.1-3.8 10-9 10-6.2 0-9.4-3.9-9.4-3.9l1.7-5.5s3.3 2.8 6 2.8c1.8 0 2.5-1.4 2.5-2.5 0-4.3-7-4.5-7-11.6 0-6 4.3-11.7 12.9-11.7 3.3 0 5 1 5 1l-2.6 7.2zm4-20.3v-.7c0-2-.3-3.7-.7-5 1.8.2 3 2.3 3.8 4.7l-3.1 1zM-1177.1 634.2h-2.8l.5-2.8c.2-.7-.3-1.3-1-1.3s-1.4.6-1.6 1.3l-.5 2.8h-2.8c-.7 0-1.4.6-1.6 1.3s.3 1.3 1.1 1.3h2.8l-.6 2.8c-.2.7.3 1.3 1.1 1.3.7 0 1.4-.6 1.5-1.3l.6-2.8h2.8c.7 0 1.4-.6 1.5-1.3.2-.5-.2-1.1-.8-1.3 0 .1-.1 0-.2 0z" /></svg></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="c-heroContent__row--2 l-heroContent__row--2">
                      <div className="c-heroContent__tagLine">
                        <h2 className="u-h4">We’re eCommerce specialists <br />focusing  solely on eCommerce <br />marketing &amp; development.</h2>
                        {/* <div className="c-heroContent__featured">
                          Featured Ecommerce Casestudy
                        </div> */}
                      </div>
                      <div className="c-heroContent__scroll">
                        <div className="u-h5">Scroll</div>
                      </div>
                    </div>
                  </div>
                  <div className="l-heroContent__col--2">
                    <div className="c-hero-title">
                      <h1 className="u-hero">Trusted<br /> Ecommerce<br />Experts</h1>
                    </div>
                    <div className="c-platform-logos">
                      <ul className="c-platform-logos__list">
                        <li className="c-platform-logos__item"><svg viewBox="-1240 629 64 64"><path d="M-1206.3 665.4h8.1c2.3 0 3.8-1.3 3.8-3.3 0-1.9-1.5-3.3-3.8-3.3h-8.1c-.3 0-.5.2-.5.5v5.7c.1.2.3.4.5.4zm0 13.1h8.3c2.6 0 4.1-1.3 4.1-3.6 0-2-1.5-3.6-4.1-3.6h-8.3c-.3 0-.5.2-.5.5v6.2c.1.3.3.5.5.5zm29.6-49.3l-23.9 23.8h3.9c6.1 0 9.7 3.8 9.7 8 0 3.3-2.2 5.7-4.6 6.8-.4.2-.4.7 0 .8 2.8 1.1 4.7 3.9 4.7 7.3 0 4.7-3.2 8.5-9.3 8.5h-16.9c-.3 0-.5-.2-.5-.5v-18l-26.2 26.1c-.3.3-.1 1 .4 1h63c.2 0 .4-.2.4-.4v-63.1c.1-.4-.4-.6-.7-.3z" /></svg></li>
                        <li className="c-platform-logos__item"><svg viewBox="-1240 629 64 64"><path d="M-1194.9 638.4l2.2 2.2s4.9.4 5.1.4c.3 0 .5.2.6.5 0 .3 6.9 47.2 6.9 47.2l-16.9 3.7 2.1-54zm-2.6-1.8c-.1 0-.2 0-.3.1l-1.9.6c-1.1-3.3-3.1-6.3-6.7-6.3h-.3c-1-1.3-2.2-1.9-3.3-1.9-8.2 0-12.1 10.3-13.3 15.5-3.2 1-5.4 1.7-5.7 1.8-1.8.6-1.8.6-2.1 2.3-.2 1.3-4.8 37.4-4.8 37.4l36.3 6.8 2.1-56.3zm-9.5 2.6v.4l-6.3 2c1.2-4.7 3.5-7 5.5-7.9.5 1.2.8 3 .8 5.5zm-3.2-7.9c.4 0 .7.1 1.1.4-2.6 1.2-5.4 4.4-6.6 10.6l-5 1.6c1.3-4.8 4.6-12.6 10.5-12.6zm1.4 27.9s-2.1-1.1-4.7-1.1c-3.8 0-4 2.4-4 3 0 3.3 8.6 4.6 8.6 12.3 0 6.1-3.8 10-9 10-6.2 0-9.4-3.9-9.4-3.9l1.7-5.5s3.3 2.8 6 2.8c1.8 0 2.5-1.4 2.5-2.5 0-4.3-7-4.5-7-11.6 0-6 4.3-11.7 12.9-11.7 3.3 0 5 1 5 1l-2.6 7.2zm4-20.3v-.7c0-2-.3-3.7-.7-5 1.8.2 3 2.3 3.8 4.7l-3.1 1zM-1177.1 634.2h-2.8l.5-2.8c.2-.7-.3-1.3-1-1.3s-1.4.6-1.6 1.3l-.5 2.8h-2.8c-.7 0-1.4.6-1.6 1.3s.3 1.3 1.1 1.3h2.8l-.6 2.8c-.2.7.3 1.3 1.1 1.3.7 0 1.4-.6 1.5-1.3l.6-2.8h2.8c.7 0 1.4-.6 1.5-1.3.2-.5-.2-1.1-.8-1.3 0 .1-.1 0-.2 0z" /></svg></li>
                      </ul>
                    </div>
                  </div>
                  <div style={{ backgroundImage: `url(${this.createBackgroundImage('herobg-colours')})` }} className="c-heroBg" />
                </section>
              </div>
            </div>
          </div>
          <section className="c-homeIntro l-homeIntro">
            <div className="vc-bg-lines dark"><div /><div /><div /><div /><div /><div /><div /></div>
            <div className="l-homeIntro__spacing">
              <h2 className="c-homeIntro__heading">
                <span className="c-homeIntro__heading__splitText u-hero">Every Maverick</span>
                <span className="c-homeIntro__heading__splitText u-hero">Needs a Goose.</span>
              </h2>
              <p className="c-homeIntro__intro u-impactIntro">Your Ecommerce Wingman. Proactive and reliable in nature. We have your back.
              As trusted Ecommerce experts in New Zealand, we work with ambitious merchants helping them sell more. Without breaking a sweat, begin future proofing your business.
              Let's accelerate your orders and <strong>goose</strong> the metrics that matter. <br /><span className="o-tgQuote">"It's time to buzz the tower." - Maverick</span>
              </p>
            </div>
            <div className="c-homeIntro__image l-homeIntro__image u-container u-container--m">
              <div className="l-homeIntro__image__wrap">
                {/* <img alt="BigCommerce Web Developer" src={homeIntroImage} /> */}
                <div className="embed-container"><iframe src="https://player.vimeo.com/video/215769092" frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" /></div>
              </div>
            </div>
          </section>
          <section className="c-homeServices l-homeServices">
            <div className="vc-bg-lines dark"><div /><div /><div /><div /><div /><div /><div /></div>
            <div className="l-homeServices__spacing">
              <div className="u-container u-container--heading">
                <h2 className="c-homeServices__heading l-homeServices__heading">
                      Right Place. Right Time. We Can Help.
                </h2>
                <p className="c-homeServices__intro l-homeServices__intro u-intro">We work with ambitious merchants helping them sell more, and grow fast. <br /> We deliver solid results from an adaptive, iterable approach.
                </p><br />
                <span className="o-tgQuote">"It takes a lot more than just fancy flying." - Charlie</span>
                {/* <Image alt="BigCommerce Auckland" className="o-bcIcon" publicId="bc-logo-icon-dark.png" secure="true" width="auto" quality="auto" dpr="auto" /> */}
              </div>
              <div className="c-homeServices__grid l-homeServices__grid u-container u-container--m">
                <div className="c-homeServices__service">
                  <h3 className="c-homeServices__service__serviceHeader u-h4">Plan</h3>
                  <p>You can never ask enough questions. We start by listening, asking…and listening some more, to gain a deep understanding of your goals.</p>
                  <p>This approach accelerates the strategic process, and builds consensus throughout the entire engagement.</p>
                </div>
                <div className="c-homeServices__service">
                  <h3 className="c-homeServices__service__serviceHeader u-h4">Deliver</h3>
                  <p>Progressive enhancement is the key. We take an iterative approach. Picking off low hanging fruit and implementing industry standards. Massive impact, minimal fuss.</p>
                  <p>The outcome; Measurable results within a short time period and a faster ROI.</p>
                </div>
                <div className="c-homeServices__service">
                  <h3 className="c-homeServices__service__serviceHeader u-h4">Iterate</h3>
                  <p>Agile and always learning. We immerse ourselves in your brand.</p>
                  <p>Together, month by month we surface actionable insights and make strategic recommendations.</p>
                  <p>We deliver cost effective, incremental value that is sustainable and measurable.</p>
                </div>
              </div>

             

              <div className="c-hero l-hero overlap-wrapper">
            <div className="overlap-bottom">
              <div className="overlap-sticky">
                <picture>
                  <source media="(max-width: 768px)" srcSet={gooseAwardsMob} />
                  <img className="image-cover" alt="BigCommerce Awards" src={gooseAwards} />
                </picture>
                {/* <div className="c-homeIntro__image__hashtag u-large">
                  <a href="https://www.instagram.com/wearegoose.co.nz/">#thegaggle</a>
                </div>
                <div className="o-socialIcons">
                  <a className="o-socialIcons__icon o-socialIcons__icon--inverse" href="https://www.instagram.com/wearegoose.co.nz/"><IconInsta /> <span>Follow Us</span></a>
                </div> */}
                {/* <img className="image-cover" alt="A group of young kids laugh, framed by a rainbow in the sky above them." src="https://assets.website-files.com/6000971fd4b15ea4ee9f6405/602725dacb8bdcf9ea3936db_hero-slide__saving-orphans.jpg" /> */}
              </div>
            </div>
            <div className="overlay-top">
            <div className="c-bigCommerceBenefits c-bigCommerceBenefits--active">
                <div className="u-container u-container--m">
                  <div className="l-bigCommerceBenefits__badge">
                    <Image alt="Certified BigCommerce Partner" publicId="bc-badge" />
                  </div>
                  <div className="c-bigCommerceBenefits__heading l-bigCommerceBenefits__heading">
                    <h3 className="l-bigCommerceBenefits__heading__header u-h1">Why Choose BigCommerce?</h3>
                    <p className="u-intro">BigCommerce gives you the ability to innovate, outperform and overachieve - no wonder it is the industry’s most versatile solution for advancing your commerce experiences. A highly-extensible SaaS solution that takes the onus of security and scalability to fast-track your growth.</p> <br />
                    <p>"Let's turn and burn!" - Nick, in the air</p>
                  </div>
                  <div className="c-bigCommerceBenefits__benefits l-bigCommerceBenefits__benefits">
                    {/* <div className="c-bigCommerceBenefits__benefits__benefit l-bigCommerceBenefits__benefits__benefit">
                      <h4 className="o-benefit__header u-hero">Data Migration</h4>
                      <p>One of the more difficult challenges of running your own eCommerce website is dealing with speed – as in how quickly your website loads. That’s because speed matters.</p>
                    </div> */}
                    <div className="c-bigCommerceBenefits__benefits__benefit l-bigCommerceBenefits__benefits__benefit">
                      <h4 className="o-benefit__header u-hero">Modern Design</h4>
                      <p>BigCommerce's revolutionary design platform gives you the freedom to create stores that engage shoppers and make more sales. You can choose from a wide selection of pre-built responsive e-commerce templates or completely build your own to fit your specific needs. Imagination is the only barrier.</p>
                    </div>
                    <div className="c-bigCommerceBenefits__benefits__benefit l-bigCommerceBenefits__benefits__benefit">
                      <h4 className="o-benefit__header u-hero">Speed &amp; Security</h4>
                      <p>One of the challenges facing merchants is making sure your ecommerce website loads quickly. Speed matters. BigCommerce is setup for unparalleled performance. BigCommerce merchants benefit from industry-leading performance, stability and security, including an average uptime of more than 99.99%. Sites will load fast and maximise conversion. BigCommerce also takes care of security for you with Level 1 PCI compliance.</p>
                    </div>
                    <div className="c-bigCommerceBenefits__benefits__benefit l-bigCommerceBenefits__benefits__benefit">
                      <h4 className="o-benefit__header u-hero">Built-in Features</h4>
                      <p>We call BigCommerce an all-in-one platform. Right out of the box, you get a heap of advanced features to help scale your orders. From faceted search to advanced shipping and payment options, eBay and Amazon connectors, optimised checkout and abandoned cart emails. The list goes on.</p>
                    </div>
                    <div className="c-bigCommerceBenefits__benefits__benefit l-bigCommerceBenefits__benefits__benefit">
                      <h4 className="o-benefit__header u-hero">Marketing Focus</h4>
                      <p>BigCommerce takes away the headache of security and troublesome updates letting you concentrate on marketing your store.  Make use of BigCommerce's Channel Manager to simplify selling on multiple channels. Drive traffic to the store with best in class SEO baked in.</p>
                    </div>
                    <div className="c-bigCommerceBenefits__benefits__benefit l-bigCommerceBenefits__benefits__benefit">
                      <h4 className="o-benefit__header u-hero">Ease of Use</h4>
                      <p>
                      BigCommerce is easy to use. The backend is simple and intuitive. You will be packing and shipping orders in no time. Need additional functionality? BigCommerce provides 1-click app installations direct from the backend. Need to know the health of your online store? Make use of the advanced reporting BC offers.
                      </p>
                    </div>
                    <div className="c-bigCommerceBenefits__benefits__benefit l-bigCommerceBenefits__benefits__benefit">
                      <h4 className="o-benefit__header u-hero">Integrate Anywhere</h4>
                      <p>The best selection of ecommerce business apps and integrations BigCommerce's marketplace has an industry-leading selection of third-party apps and integrations, all carefully curated to make sure we only feature the best solutions. You can seamlessly connect your store to the software you need to sell effectively.</p>
                    </div>
                    {/* <div className="c-bigCommerceBenefits__benefits__benefit">
                      <div className="c-cutomerQuote">
                        <div className="c-cutomerQuote__image">
                          <img alt="BigCommerce Certified Partner" src={rcplane} />
                        </div>
                        <div className="c-cutomerQuote__quote">
                          "I was stuck with a proprietary eCommerce platform. Goose migrated my entire 15k+ product catalogue &amp; customers. They rapidly got my BigCommerce store live, with a seemless transition"
                        </div>
                        <div className="c-cutomerQuote__author">
                          -- By Daryl at RC Hobbies / <a href="https//www.rchobbies.co.nz">rchobbies.co.nz</a>
                        </div>
                      </div>
                    </div>
                    <div className="c-bigCommerceBenefits__benefits__benefit">
                      <div className="c-cutomerQuote">
                        <div className="c-cutomerQuote__image">
                          <img alt="BigCommerce Certified Partner" src={paint} />
                        </div>
                        <div className="c-cutomerQuote__quote">
                          "
                          I got in touch with Goose because I liked the name. I have been using their services for several months now, &amp; growth has been continuous, steady &amp; measurable. Their key focus is growing together, investing their time in understanding me, my business &amp; my market."
                        </div>
                        <div className="c-cutomerQuote__author">
                          -- By Matt at Millin / <a href="https://www.millin.co.nz/">millin.co.nz</a>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="c-bigCommerceBenefits__freetrial">
                    <div className="c-freeTrialBox l-freeTrialBox">
                      <div className="c-freeTrialBox__bclogo">
                        <Image alt="BigCommerce Logo" publicId="bc-logo-icon-light" />
                      </div>
                      <div className="c-freeTrialBox__content">
                        <h4 className="c-freeTrialBox__header u-h3">30 Day Free Trial <span className="o-smallprint">*</span></h4>
                        <p className="c-freeTrialBox__text">Would you like to try before you buy? </p>
                        <p>With Goose you can get 30 days instead of 15.....<em>we told you we had your back!</em> Let us know, we'll hook you up. No strings.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

              <div className="c-bigCommerceBenefits c-bigCommerceBenefits--shopify c-bigCommerceBenefits--active">
                <div className="u-container u-container--m">
                  <div className="l-bigCommerceBenefits__badge">
                    <Image className="logo" alt="Certified Shopify Partner" publicId="shopify-partner-logo" />
                  </div>
                  <div className="c-bigCommerceBenefits__heading l-bigCommerceBenefits__heading">
                    <h3 className="l-bigCommerceBenefits__heading__header u-h1">Why Choose Shopify?</h3>
                    <p className="u-intro">A powerful ecommerce platform with the freedom to grow, adapt, and evolve. Costly builds, crashed sites, and infrastructure that burns through development resources are all things of the past. Shopify Plus gives you a multi-channel platform with unmatched scalability so you can grow your business, instead of worrying about your website.</p> <br />
                    <p>"Show me the way home, honey." - Goose</p>
                  </div>
                  <div className="c-bigCommerceBenefits__benefits l-bigCommerceBenefits__benefits">
                    {/* <div className="c-bigCommerceBenefits__benefits__benefit l-bigCommerceBenefits__benefits__benefit">
                      <h4 className="o-benefit__header u-hero">Data Migration</h4>
                      <p>One of the more difficult challenges of running your own eCommerce website is dealing with speed – as in how quickly your website loads. That’s because speed matters.</p>
                    </div> */}
                    <div className="c-bigCommerceBenefits__benefits__benefit l-bigCommerceBenefits__benefits__benefit">
                      <h4 className="o-benefit__header u-hero">Trusted worldwide</h4>
                      <p>It's no secret that Shopify is the most popular ecommerce platform, trusted by over 800,000 businesses worldwide.</p>
                    </div>
                    <div className="c-bigCommerceBenefits__benefits__benefit l-bigCommerceBenefits__benefits__benefit">
                      <h4 className="o-benefit__header u-hero">Marketing powerhouse</h4>
                      <p>Automate campaigns, product drops, and high-demand sales</p>
                    </div>
                    <div className="c-bigCommerceBenefits__benefits__benefit l-bigCommerceBenefits__benefits__benefit">
                      <h4 className="o-benefit__header u-hero">End-to-end solution</h4>
                      <p>Shopify handles everything from marketing, payments, to secure checkout and shipping.</p>
                    </div>
                    <div className="c-bigCommerceBenefits__benefits__benefit l-bigCommerceBenefits__benefits__benefit">
                      <h4 className="o-benefit__header u-hero">Customisable</h4>
                      <p>No design skills needed. You have complete control over the look and feel of your website, from its layout to content and colors.</p>
                    </div>
                    <div className="c-bigCommerceBenefits__benefits__benefit l-bigCommerceBenefits__benefits__benefit">
                      <h4 className="o-benefit__header u-hero">Safe and Secure</h4>
                      <p>Level 1 web security. Unleash IT from repetitive tasks, maintaining performance, and monitoring risks.</p>
                    </div>
                    <div className="c-bigCommerceBenefits__benefits__benefit l-bigCommerceBenefits__benefits__benefit">
                      <h4 className="o-benefit__header u-hero">Multi-channel Sales</h4>
                      <p>Launch new sales channels across marketplaces, social, mobile, and in real life. Go global through dedicated storefronts in multiple languages and currencies.</p>
                    </div>
                    {/* <div className="c-bigCommerceBenefits__benefits__benefit">
                      <div className="c-cutomerQuote">
                        <div className="c-cutomerQuote__image">
                          <img alt="BigCommerce Certified Partner" src={rcplane} />
                        </div>
                        <div className="c-cutomerQuote__quote">
                          "I was stuck with a proprietary eCommerce platform. Goose migrated my entire 15k+ product catalogue &amp; customers. They rapidly got my BigCommerce store live, with a seemless transition"
                        </div>
                        <div className="c-cutomerQuote__author">
                          -- By Daryl at RC Hobbies / <a href="https//www.rchobbies.co.nz">rchobbies.co.nz</a>
                        </div>
                      </div>
                    </div>
                    <div className="c-bigCommerceBenefits__benefits__benefit">
                      <div className="c-cutomerQuote">
                        <div className="c-cutomerQuote__image">
                          <img alt="BigCommerce Certified Partner" src={paint} />
                        </div>
                        <div className="c-cutomerQuote__quote">
                          "
                          I got in touch with Goose because I liked the name. I have been using their services for several months now, &amp; growth has been continuous, steady &amp; measurable. Their key focus is growing together, investing their time in understanding me, my business &amp; my market."
                        </div>
                        <div className="c-cutomerQuote__author">
                          -- By Matt at Millin / <a href="https://www.millin.co.nz/">millin.co.nz</a>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>


              {/* <div className="c-bigCommerceBenefits c-bigCommerceBenefits--swell c-bigCommerceBenefits--active">
                <div className="u-container u-container--m">
                  <div className="l-bigCommerceBenefits__badge">
                    <Image className="logo" alt="Certified Swell Partner" publicId="swell-logo.png" />
                  </div>
                  <div className="c-bigCommerceBenefits__heading l-bigCommerceBenefits__heading">
                    <h3 className="l-bigCommerceBenefits__heading__header u-h1">Why Choose Swell?</h3>
                    <p className="u-intro">Swell is Ecommerce without limits. Offering merchants to drive growth and offer unique shopping experiences without a patchwork of apps. From products to checkout, Swell is customisable and made for scale.</p> <br />
                    <p>"I Feel The Need… The Need For Speed!" - Goose</p>
                  </div>
                  <div className="c-bigCommerceBenefits__benefits l-bigCommerceBenefits__benefits">
                    {/* <div className="c-bigCommerceBenefits__benefits__benefit l-bigCommerceBenefits__benefits__benefit">
                      <h4 className="o-benefit__header u-hero">Data Migration</h4>
                      <p>One of the more difficult challenges of running your own eCommerce website is dealing with speed – as in how quickly your website loads. That’s because speed matters.</p>
                    </div> */}
                    {/* <div className="c-bigCommerceBenefits__benefits__benefit l-bigCommerceBenefits__benefits__benefit">
                      <h4 className="o-benefit__header u-hero">Advanced features</h4>
                      <p>Increase revenue with native features like customer groups, subscriptions, and headless themes.</p>
                    </div>
                    <div className="c-bigCommerceBenefits__benefits__benefit l-bigCommerceBenefits__benefits__benefit">
                      <h4 className="o-benefit__header u-hero">Deeper integrations</h4>
                      <p>Swell build integrations natively where it makes sense. Flexible architecture enables seamless integrations with 3rd-party vendors.</p>
                    </div>
                    <div className="c-bigCommerceBenefits__benefits__benefit l-bigCommerceBenefits__benefits__benefit">
                      <h4 className="o-benefit__header u-hero">Get to market faster</h4>
                      <p>Build sophisticated experiences faster, with less resources. Start with a headless storefront theme and focus on what makes your business unique.</p>
                    </div>
                    <div className="c-bigCommerceBenefits__benefits__benefit l-bigCommerceBenefits__benefits__benefit">
                      <h4 className="o-benefit__header u-hero">Wholesale, B2B, Marketplace</h4>
                      <p>Set bulk, group, and customer-specific pricing. Offer business customers the same polished shopping experience they’ve become used to as consumers.</p>
                    </div>
                    <div className="c-bigCommerceBenefits__benefits__benefit l-bigCommerceBenefits__benefits__benefit">
                      <h4 className="o-benefit__header u-hero">Global scale</h4>
                      <p>Our hands-off infrastructure makes it easy to scale your brand when things take off. Swell’s autoscaling services and worldwide CDN means you can grow faster with a small team at a fraction of the cost.</p>
                    </div>
                    <div className="c-bigCommerceBenefits__benefits__benefit l-bigCommerceBenefits__benefits__benefit">
                      <h4 className="o-benefit__header u-hero">Subscriptions, preorders, TBYB</h4>
                      <p>Sell products on subscription, preorder, try-before-you-buy, and custom payment plans without using other services or apps. It’s all built-in.</p>
                    </div>

                    {/* <div className="c-bigCommerceBenefits__benefits__benefit">
                      <div className="c-cutomerQuote">
                        <div className="c-cutomerQuote__image">
                          <img alt="BigCommerce Certified Partner" src={rcplane} />
                        </div>
                        <div className="c-cutomerQuote__quote">
                          "I was stuck with a proprietary eCommerce platform. Goose migrated my entire 15k+ product catalogue &amp; customers. They rapidly got my BigCommerce store live, with a seemless transition"
                        </div>
                        <div className="c-cutomerQuote__author">
                          -- By Daryl at RC Hobbies / <a href="https//www.rchobbies.co.nz">rchobbies.co.nz</a>
                        </div>
                      </div>
                    </div>
                    <div className="c-bigCommerceBenefits__benefits__benefit">
                      <div className="c-cutomerQuote">
                        <div className="c-cutomerQuote__image">
                          <img alt="BigCommerce Certified Partner" src={paint} />
                        </div>
                        <div className="c-cutomerQuote__quote">
                          "
                          I got in touch with Goose because I liked the name. I have been using their services for several months now, &amp; growth has been continuous, steady &amp; measurable. Their key focus is growing together, investing their time in understanding me, my business &amp; my market."
                        </div>
                        <div className="c-cutomerQuote__author">
                          -- By Matt at Millin / <a href="https://www.millin.co.nz/">millin.co.nz</a>
                        </div>
                      </div>
                    </div> */}
                  {/* </div>
                </div>
              </div> */}


            </div>
          </section>
          <section className="c-clients l-clients__spacing">
            <div className="u-container u-container--heading c-clients__header-container">
              <h2 className="c-clients__heading l-clients__heading u-hero">
                Clients
              </h2>
              <p className="c-clients__intro l-homeServices__intro u-intro">A selection of our favourite Ecommerce Clients</p><br />
              {/* <span className="o-tgQuote">"Son, your ego is writing checks your body can’t cash!" - Stinger</span> */}
            </div>

            <div className="u-container u-container--m">
                <div className="c-clients__logos l-clients__logos">
                <img src={nPostClient}/>
                <img src={cGroupClient}/>
                <img src={mazdaClient}/>
                <img src={kohlarClient}/>
                <img src={enovisClient}/>
                <img src={tSpaceClient}/>
                <img src={zwillingClient}/>
                <img src={nSocksClient}/>
                <img src={mBenClient}/>
                <img src={vDyksClient}/>
                <img src={eApplienceClient}/>
                <img src={eFieldClient}/>
                <img src={queenBClient}/>
                <img src={cSlClient}/>
                <img src={lDirectClient}/>
                <img src={makanaClient}/>
                <img src={bstoreClinet}/>
                <img src={cloggerClient}/>
                <img src={jsBrooksClient}/>
                <img src={kiwiVeloClient}/>
                <img src={bpureClient}/>
                <img src={tShedClient}/>
                <img src={bmatesClient}/>
                <img src={brewsClient}/>
              </div>
            </div>
            <div className="u-container u-container--m">
              <div className="c-clients__testimonials">
                <div className="c-homeServices__service">
                  <div className="c-cutomerQuote">
                    <div className="c-cutomerQuote__image">
                      <Image alt="BigCommerce Certified Partner" publicId="stamps" />
                    </div>
                    <div className="c-cutomerQuote__quote">
                              "Our eCommerce website was sitting on a legacy platform.  Goose did a complete overhaul of our website, making it easier to use for our customers (both new and old); they also successfully integrated the site with our Oracle ERP. All of this was done with a very tight timeline where Goose demonstrated their agility time and again (not to mention their professionalism and patience with us). We’ve already noticed an uptick in growth online and have had a lot of positive feedback from our customers."
                    </div>
                    <div className="c-cutomerQuote__author">
                              -- By Kelly at NZ Post Collectables / <a href="https://collectables.nzpost.co.nz/">collectables.nzpost.co.nz</a>
                    </div>
                  </div>
                </div>
                <div className="c-homeServices__service">
                    <div className="c-cutomerQuote">
                      <div className="c-cutomerQuote__image">
                        <Image
                          alt="BigCommerce Developer Partner"
                          publicId="comfort_group"
                        />
                      </div>
                      <div className="c-cutomerQuote__quote">
                        "We engaged with Goose when we started to build a new
                        bedding platform. We found them to be exceptionally
                        knowledgeable, accommodating and engaged. Each business
                        has internal issues that need to be dealt with during an
                        integration process and they were really helpful in
                        being able to propose ways for us to get the
                        functionality we wanted, but not at the cost of months
                        of customizations and complexity. All the team are
                        friendly and a pleasure to work with. Our site has been
                        operational now for a few months and is working a
                        treat!"
                      </div>
                      <div className="c-cutomerQuote__author">
                        -- By Simon at The Comfort Group /{" "}
                        <a href="https://www.thecomfortgroup.co/">
                          thecomfortgroup.co
                        </a>
                      </div>
                    </div>
                  </div>
                {/* <div className="c-homeServices__service">
                  <div className="c-cutomerQuote">
                    <div className="c-cutomerQuote__image">
                      <Image alt="BigCommerce Developer Partner" publicId="flowers" />
                    </div>
                    <div className="c-cutomerQuote__quote">
                              "We have struggled over the years to find the hand holding we required to grow our online business and to keep up with all the changes and opportunities online retailing offers. We have spent a lot of money over the years that has basically been very wasteful, also delaying our growth. We really love the fast efficient service we get from Goose for both development projects and for our day to day marketing. They are the perfect fit for us."
                    </div>
                    <div className="c-cutomerQuote__author">
                              -- By Kerry at Wild Poppies / <a href="https://www.wildpoppies.co.nz/">wildpoppies.co.nz</a>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </section>
          <section className="c-homeWork">
            <div className="u-container u-container--heading">
              <h2 className="c-homeServices__heading l-homeServices__heading u-hero">
                Work
              </h2>
              <p className="c-homeServices__intro l-homeServices__intro u-intro">Our favourite client once told us we're too modest.
              </p><br />
              <span className="o-tgQuote">"Son, your ego is writing checks your body can’t cash!" - Stinger</span>
            </div>
            <div className="c-homeIntro__image l-homeIntro__image u-container u-container--m">
              <Image alt="BigCommerce Website NZ Post Collectables" publicId="nzp-collectables" />
              <div className="c-homeIntro__image__hashtag u-large">
                <a href="https://collectables.nzpost.co.nz/">collectables.nzpost.co.nz</a>
              </div>
              <div className="c-homeWork__results c-homeWork__results--centre">
                <h3 className="c-homeWork__results__header l-homeWork__results__header u-h1">Full Replatform</h3>
                <div className="c-homeWork__results__subheader l-homeWork__results__subheader u-h4">From Drupal CMS</div>
              </div>
              <div className="c-homeWork__results c-homeWork__results--centre">
                <h3 className="c-homeWork__results__header l-homeWork__results__header u-h1">Oracle ERP</h3>
                <div className="c-homeWork__results__subheader l-homeWork__results__subheader u-h4">Custom Integration</div>
              </div>
              <div className="c-homeWork__results c-homeWork__results--centre">
                <h3 className="c-homeWork__results__header l-homeWork__results__header u-h1">Personalised Stamps</h3>
                <div className="c-homeWork__results__subheader l-homeWork__results__subheader u-h4">Interactive Personalised Stamp App</div>
              </div>
            </div>
            <div className="c-homeIntro__image l-homeIntro__image u-container u-container--m">
              <Image alt="BigCommerce Website Magness Benrow" publicId="magness-v2" />
              <div className="c-homeIntro__image__hashtag u-large">
                <a href="https://magnessbenrow.co.nz">magnessbenrow.co.nz</a>
              </div>
              <div className="c-homeWork__results c-homeWork__results--centre">
                <h3 className="c-homeWork__results__header l-homeWork__results__header u-h1">187.04% Increase</h3>
                <div className="c-homeWork__results__subheader l-homeWork__results__subheader u-h4">In Conversion Rate</div>
              </div>
              <div className="c-homeWork__results c-homeWork__results--centre">
                <h3 className="c-homeWork__results__header l-homeWork__results__header u-h1">381% Increase</h3>
                <div className="c-homeWork__results__subheader l-homeWork__results__subheader u-h4">In Online Revenue</div>
              </div>
              <div className="c-homeWork__results c-homeWork__results--centre">
                <h3 className="c-homeWork__results__header l-homeWork__results__header u-h1">23.69% Increase</h3>
                <div className="c-homeWork__results__subheader l-homeWork__results__subheader u-h4">In Average Order Value</div>
              </div>
              <div className="c-homeWork__results c-homeWork__results--centre">
                <h3 className="c-homeWork__results__header l-homeWork__results__header u-h1 u-brand-red">See How We Did It</h3>
                <a href="/magness-bigcommerce">Click here to read the case study &#8594;</a>
              </div>
            </div>

            <div className="c-homeIntro__image l-homeIntro__image u-container u-container--m">
                <Image
                  alt="BigCommerce Developers Sunshine Coast"
                  publicId="kohler-au_2x"
                />
                <div className="c-homeIntro__image__hashtag u-large u-color-white">
                  <a href="https://kohler.com/">Kohler</a>
                </div>
                <div className="c-homeWork__results c-homeWork__results--centre">
                  <h3 className="c-homeWork__results__header l-homeWork__results__header u-h1">
                    Bespoke Design &amp; Build
                  </h3>
                  <div className="c-homeWork__results__subheader l-homeWork__results__subheader u-h4">
                    BigCommerce Ecommerce
                  </div>
                </div>
                <div className="c-homeWork__results c-homeWork__results--centre">
                  <h3 className="c-homeWork__results__header l-homeWork__results__header u-h1">
                    Platform Migration
                  </h3>
                  <div className="c-homeWork__results__subheader l-homeWork__results__subheader u-h4">
                    Legacy CMS
                  </div>
                </div>
                <div className="c-homeWork__results c-homeWork__results--centre">
                  <h3 className="c-homeWork__results__header l-homeWork__results__header u-h1">
                    Onsite SEO
                  </h3>
                  <div className="c-homeWork__results__subheader l-homeWork__results__subheader u-h4">
                    Full SEO &amp; Performance Optimisation
                  </div>
                </div>
              </div>

            {/* <div className="c-homeIntro__image l-homeIntro__image u-container u-container--m">
              <Image alt="BigCommerce Website Splash Australia" publicId="wp-tablet" />
              <div className="c-homeIntro__image__hashtag u-large">
                <a href="https://www.wildpoppies.co.nz/">wildpoppies.co.nz</a>
              </div>
              <div className="c-homeWork__results c-homeWork__results--centre">
                <h3 className="c-homeWork__results__header l-homeWork__results__header u-h1">60.80% Increase</h3>
                <div className="c-homeWork__results__subheader l-homeWork__results__subheader u-h4">In Online Revenue</div>
              </div>
              <div className="c-homeWork__results c-homeWork__results--centre">
                <h3 className="c-homeWork__results__header l-homeWork__results__header u-h1">82.45% Increase</h3>
                <div className="c-homeWork__results__subheader l-homeWork__results__subheader u-h4">In Unique purchases</div>
              </div>
              <div className="c-homeWork__results c-homeWork__results--centre">
                <h3 className="c-homeWork__results__header l-homeWork__results__header u-h1">Custom Checkout</h3>
                <div className="c-homeWork__results__subheader l-homeWork__results__subheader u-h4">Developed Specifically For Gifting</div>
              </div>
            </div> */}
          </section>
          <footer className="c-footer l-footer">
            <div className="c-footer__content l-footer__content">
              <div className="c-footer__header">
                <span>
                  <h5 className="u-epic"><Link onClick={this.handleContactModelClick} to="/">Talk To Me Goose</Link></h5>
                  <div className="c-footer__subheader">
                    <span className="o-tgQuote">- Maverick (a.k.a You)</span>
                  </div>
                </span>
              </div>
              <div className="c-footer__legal">
                <div className="c-footer__legal__copyright">
                  <span aria-label="copyright" role="img">&copy;</span> {new Date().getFullYear()} Goose Ltd - Ecommerce Growth Agency, <br />Auckland, New Zealand.
                </div>
                <div className="c-footer__legal__social">
                  <div className="o-socialIcons">
                    <div className="o-socialIcons__icon">
                      <a href={socialLinks.facebook}>
                        <IconFacebook />
                      </a>
                    </div>
                    <div className="o-socialIcons__icon">
                      <a href={socialLinks.instagram}>
                        <IconInsta />
                      </a>
                    </div>
                    <div className="o-socialIcons__icon">
                      <a href={socialLinks.youtube}>
                        <IconYoutube />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="c-footer__girtieTheGoose">
                <Image alt="Goose BigCommerce Web Design" publicId="goose" />
              </div>
            </div>
          </footer>

          <ContactModel toggleContactModel={this.handleContactModelClick} siteMeta={this.props.data.site.siteMetadata} ContactModelStatus={this.state.contactModelActive} />

        </CloudinaryContext>
      </div>
      </Layout>
    );
  }
}

export default IndexRoute;

export const query = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        contactNumber
        contactEmail
        menu {
          label
          path
        }
        author {
          name
        }
        socialLinks {
          facebook
          instagram
          youtube
        }
      }
    }
  }
`;
